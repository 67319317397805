<template>
  <div class="bg-gradient-to-r px-4 from-gray-700 via-gray-900 to-black min-h-screen"  @click="selectedIndex = null">
    <h2 class="py-8 text-center text-4xl font-bold text-white lg:py-10 md:py-10">
      Các dịch vụ khác
    </h2>

    <!-- Sử dụng lưới với 3 cột để chia 6 phần tử thành 2 hàng -->
    <div class="h-auto gap-x-[40px] gap-y-[40px] lg:h-[500px] lg:gap-x-[100px] lg:gap-y-[100px] md:h-[600px] md:gap-x-[30px] md:gap-y-[30px] grid grid-cols-1 md:grid-cols-3 ">
      <div 
        v-for="(service, index) in services" 
        :key="index" 
        @click.stop="toggleDetail(index)" 
        class="h-[200px] md:h-[300px] lg:h-[200px] relative flex flex-col items-center bg-white rounded-lg p-4 shadow-lg cursor-pointer transform hover:scale-105 transition-transform duration-300"
      >
        <img :src="service.image" class="w-16 h-16 mb-4" alt="image service">
        <h3 class="text-lg text-center font-semibold">{{ service.name }}</h3>

        <!-- Slide-in Detail Section -->
        <div 
          v-if="selectedIndex === index" 
          class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80 text-white rounded-lg p-4 animate-slide-right"
        >
          <div class="text-center">
            <img :src="service.icon" alt="service icon" class="w-12 h-12 mb-2 mx-auto" />
            <h3 class="text-xl font-bold mb-2">{{ service.name }}</h3>
            <p class="text-sm">{{ service.description.vi }}</p>
            <p class="text-sm mt-2">{{ service.description.en }}</p>
          </div>
        </div>
      </div>
    </div>

    <OtherServicesDetail class="mt-10"/>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import OtherServicesDetail from "../components/OtherServicesDetail.vue";

const services = ref([
  {
    name: 'Facebook Likes & Follows',
    description: {
      vi: 'Tăng like và follow Facebook một cách nhanh chóng và hiệu quả.',
      en: 'Quickly and effectively increase Facebook likes and follows.'
    },
    image: require('@/assets/images/others/fb-icon.webp'),
    icon: require('@/assets/images/others/fb-icon.webp'),
  },
  {
    name: 'Build Facebook Groups',
    description: {
      vi: 'Tạo và tăng member các nhóm Facebook để gắn kết cộng đồng của bạn.',
      en: 'Create and grow Facebook group members to strengthen your community.'
    },
    image: require('@/assets/images/others/fb-group.webp'),
    icon: require('@/assets/images/others/fb-group.webp'),
  },
  {
    name: 'Facebook Ads',
    description: {
      vi: 'Chạy và quản lý các chiến dịch quảng cáo Facebook hiệu quả.',
      en: 'Run and manage Facebook ad campaigns effectively.'
    },
    image: require('@/assets/images/others/fb-ads.webp'),
    icon: require('@/assets/images/others/fb-ads.webp'),
  },
  {
    name: 'TikTok Likes & Follows',
    description: {
      vi: 'Tăng like, view, follow cho kênh Tiktok của bạn.',
      en: 'Increase likes, views, and follows for your TikTok channel.'
    },
    image: require('@/assets/images/others/tiktok-icon.webp'),
    icon: require('@/assets/images/others/tiktok-icon.webp'),
  },
  {
    name: 'Threads Likes & Follows',
    description: {
      vi: 'Nâng cao sự tương tác trên Threads với chiến lược đặc biệt của chúng tôi.',
      en: 'Boost engagement on Threads with our unique strategy.'
    },
    image: require('@/assets/images/others/thread-icon.webp'),
    icon: require('@/assets/images/others/thread-icon.webp'),
  },
  {
    name: 'Telegram Channel Growth',
    description: {
      vi: 'Phát triển kênh Telegram của bạn với các chiến lược hướng đến khán giả mục tiêu.',
      en: 'Grow your Telegram channel with strategies targeted at your audience.'
    },
    image: require('@/assets/images/others/telegram-icon.webp'),
    icon: require('@/assets/images/others/telegram-icon.webp'),
  },
]);

const selectedIndex = ref(null);

function toggleDetail(index) {
  selectedIndex.value = selectedIndex.value === index ? null : index;
}
</script>

<style scoped>
@keyframes slide-right {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-right {
  animation: slide-right 0.5s ease-in-out forwards;
}
</style>
