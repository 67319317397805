<template>
<div class="bg-gray-50 text-gray-800 p-6 rounded-lg shadow-lg max-w-7xl mx-auto">
    <h1 class="text-4xl mb-6">
        Các dịch vụ mạng xã hội - Lợi thế cạnh tranh vượt trội cho doanh nghiệp của bạn
    </h1>
    <p class="mb-6 leading-relaxed">
        Trong thời đại số hóa, việc phát triển thương hiệu và xây dựng cộng đồng trên các nền tảng mạng xã hội đã trở thành yếu tố sống còn cho cá nhân và doanh nghiệp. Để hỗ trợ quá trình này, các dịch vụ tăng trưởng mạng xã hội như tăng 
    <strong class="font-semibold">Facebook Likes & Follows</strong>, <strong class="font-semibold">Build Facebook Groups</strong>, <strong class="font-semibold">Facebook Ads</strong>, <strong class="font-semibold">TikTok Likes & Follows</strong>, <strong class="font-semibold">Threads Likes & Follows</strong>, và <strong class="font-semibold">Telegram Channel Growth</strong> mang lại nhiều lợi thế đáng kể. Hãy cùng tìm hiểu chi tiết về các dịch vụ này và lý do chúng đáng để bạn đầu tư.
    </p>

    <div v-for="(service, index) in services" :key="index" class="mb-8">
    <h2 class="text-xl font-bold mb-3 text-gray-900">{{ service.name }}</h2>
    <ul class="list-disc list-inside text-gray-700 pl-4 space-y-2">
        <li v-for="(benefit, idx) in service.benefits" :key="idx">{{ benefit }}</li>
    </ul>
    <p class="mt-4 text-gray-700 leading-relaxed">
        <strong class="font-semibold"></strong> {{ service.description }}
    </p>
    </div>

    <p class="mt-6 text-gray-700 leading-relaxed">
        Với các dịch vụ tăng trưởng mạng xã hội như trên, bạn không chỉ mở rộng sự hiện diện thương hiệu mà còn xây dựng cộng đồng gắn kết, tăng cường doanh số và tối ưu hóa hiệu quả kinh doanh. Hãy bắt đầu ngay hôm nay để không bỏ lỡ cơ hội chinh phục thị trường!
    </p>
</div>
</template>

<script setup>
const services = [
{
    name: '* Facebook Likes & Follows',
    benefits: [
    'Tăng độ uy tín và sức hút của fanpage.',
    'Mở rộng tiếp cận tự nhiên (organic reach).',
    'Tiết kiệm thời gian xây dựng cộng đồng.',
    ],
    description: 'Facebook là mạng xã hội lớn nhất thế giới, với hơn 2,9 tỷ người dùng hoạt động hàng tháng. Dịch vụ tăng Facebook Likes & Follows giúp bạn đạt được lượng người theo dõi lớn và nhanh chóng, mang lại uy tín cho thương hiệu và tăng cơ hội tiếp cận khách hàng tiềm năng.',
},
{
    name: '* Build Facebook Groups',
    benefits: [
    'Tăng số lượng thành viên nhóm chất lượng.',
    'Xây dựng lòng trung thành từ cộng đồng.',
    'Hỗ trợ tiếp thị nội dung hiệu quả.',
    ],
    description: 'Dịch vụ xây dựng nhóm Facebook giúp bạn tạo và phát triển các nhóm cộng đồng mạnh mẽ, kết nối chặt chẽ với khách hàng và tăng khả năng tiếp cận các nội dung quan trọng.',
},
{
    name: '* Facebook Ads',
    benefits: [
    'Tăng độ chính xác trong nhắm mục tiêu.',
    'Tối ưu hóa chi phí quảng cáo.',
    'Tăng tỷ lệ chuyển đổi.',
    ],
    description: 'Facebook Ads là cách nhanh nhất để tiếp cận đối tượng khách hàng tiềm năng, với các chiến dịch quảng cáo tối ưu hóa, giúp bạn tiết kiệm chi phí và đạt được hiệu quả cao nhất.',
},
{
    name: '* TikTok Likes & Follows',
    benefits: [
    'Tăng khả năng hiển thị trên TikTok.',
    'Tăng độ uy tín và sức hút trên nền tảng.',
    'Mở rộng tầm ảnh hưởng với thế hệ trẻ.',
    ],
    description: 'TikTok đã trở thành một nền tảng quan trọng để xây dựng thương hiệu, đặc biệt với các thế hệ trẻ. Dịch vụ tăng TikTok Likes & Follows giúp video của bạn dễ dàng lan truyền và xây dựng kênh TikTok mạnh mẽ.',
},
{
    name: '* Threads Likes & Follows',
    benefits: [
    'Đón đầu xu hướng trên nền tảng Threads.',
    'Tăng tương tác nhanh chóng.',
    'Tăng cơ hội hợp tác và bán hàng.',
    ],
    description: 'Threads, nền tảng mới của Meta, mang đến cơ hội lớn để bạn nổi bật với dịch vụ tăng Threads Likes & Follows, giúp bạn phát triển cộng đồng nhanh chóng.',
},
{
    name: '* Telegram Channel Growth',
    benefits: [
    'Xây dựng kênh với đối tượng mục tiêu.',
    'Tăng cường giao tiếp trực tiếp với khách hàng.',
    'Hỗ trợ kinh doanh và bán hàng.',
    ],
    description: 'Telegram là nền tảng mạnh mẽ để xây dựng cộng đồng trung thành. Dịch vụ tăng trưởng kênh Telegram giúp bạn tiếp cận khách hàng mục tiêu và tăng cường hiệu quả kinh doanh.',
},
];
</script>
