<template>
  <section class="custom bg-blue-600 py-12 text-white bg-cover bg-center">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h2 class="text-3xl font-bold text-center mb-8 lg:mb-12">
        HỖ TRỢ RẤT NHIỀU LĨNH VỰC VÀ NGÀNH NGHỀ
      </h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
        <div 
          v-for="(category, index) in categories" 
          :key="index" 
          class="flex flex-col items-center bg-white text-center p-4 rounded-lg shadow-md transform transition-transform hover:scale-105 cursor-pointer"
        >
          <img :src="category.image" :alt="category.title" class="rounded-lg w-full h-32 object-cover mb-4" />
          <h3 class="font-semibold text-blue-600">{{ category.title }}</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const categories = [
  { title: 'WEBSITE BÁN HÀNG', image: require('@/assets/images/support-field/ban-hang.webp')},
  { title: 'WEBSITE BẤT ĐỘNG SẢN', image: require('@/assets/images/support-field/bat-dong-san.webp')},
  { title: 'WEBSITE CHUẨN SEO', image: require('@/assets/images/support-field/chuan-seo.webp')},
  { title: 'WEBSITE CÔNG NGHỆ', image: require('@/assets/images/support-field/cong-nghe.webp')},
  { title: 'WEBSITE DỊCH VỤ', image: require('@/assets/images/support-field/dich-vu.webp')},
  { title: 'WEBSITE DU LỊCH', image: require('@/assets/images/support-field/du-lich.webp')},
  { title: 'WEBSITE ĐIỆN MÁY', image: require('@/assets/images/support-field/dien-may.webp')},
  { title: 'WEBSITE GIÁO DỤC', image: require('@/assets/images/support-field/giao-duc.webp')},
  { title: 'WEBSITE GIỚI THIỆU', image: require('@/assets/images/support-field/gioi-thieu.webp')},
  { title: 'WEBSITE HOA, CÂY CẢNH', image: require('@/assets/images/support-field/hoa-cay-canh.webp')},
];
</script>

<style scoped>
.custom {
  border-radius: 0 0 50px 50px;
}
</style>
