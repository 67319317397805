<template>
    <div class="flex justify-start ml-4 lg:mr-8 md:mr-4 mb-4">
        <button @click="goBack" class="bg-blue-500 text-white px-4 py-2 rounded">
        Trở lại
        </button>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const goBack = () => {
const scrollPosition = sessionStorage.getItem('scrollPosition');
if (scrollPosition) {
    window.scrollTo(0, parseInt(scrollPosition)); // Quay lại vị trí cuộn đã lưu
    sessionStorage.removeItem('scrollPosition'); // Xóa vị trí đã lưu sau khi quay lại
}
router.back(); // Quay lại trang trước đó
};
</script>

<style scoped>
</style>
  