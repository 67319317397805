<template>
  <header class="bg-white shadow p-2">
    <div class="container mx-auto flex md:justify-between justify-center items-center">
      <!-- Logo -->
      <div :class="{ hidden: isMenuOpen, block: !isMenuOpen }" class="lg:w-[420px] md:w-[240px] flex flex-row items-center justify-around">
        <router-link to="/">
          <img src="../assets/images/logo/logo.webp" alt="Logo thiết kế website chuẩn SEO" class="h-[60px] lg:h-[80px]"/>
        </router-link>

        <router-link to="/">
          <h1 class="custom-font text-yellow-600 ml-[40px] text-[24px] lg:text-[32px] md:text-[18px] md:ml-0">
            Web services
          </h1>
        </router-link>
      </div>

      <!--------------- NAVIGATION --------------->
      <nav class="ml-auto">
        <!-- Nút hamburger chỉ hiển thị trên di động -->
        <button @click="toggleMenu" class="btn_custom block md:hidden ml-auto">
          <svg v-if="!isMenuOpen" class="w-6 h-6 hamburger" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
          <svg v-else class="w-6 h-6 close" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>

        <!-- Menu điều hướng -->
        <ul :class="menuClasses">
          <li @click="toggleMenu">
            <router-link to="/" class="item home-custom lg:text-[20px] block lg:px-3 md:px-2 py-[4px]">
              HOME
            </router-link>
          </li>
          <li @click="toggleMenu">
            <router-link to="/about" class="item about-custom lg:text-[20px] block lg:px-3 md:px-2 py-[4px]">
              ABOUT
            </router-link>
          </li>
          <li @click="toggleMenu">
            <router-link to="/contact" class="item contact-custom lg:text-[20px] block lg:px-3 md:px-2 py-[4px]">
              CONTACT
            </router-link>
          </li>
          <li @click="toggleMenu">
            <router-link to="/web-guide" class="item web-custom lg:text-[20px] block lg:px-3 md:px-2 py-[4px]">
              WEB GUILDE
            </router-link>
          </li>
          <li @click="toggleMenu">
            <router-link to="/other-services" class="item other-custom lg:text-[20px] block lg:px-3 md:px-2 py-[4px]">
              OTHER
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { ref, computed } from "vue";

const isMenuOpen = ref(false);

const toggleMenu = () => {
  if (window.innerWidth < 768) {
    isMenuOpen.value = !isMenuOpen.value;
  }
};

const menuClasses = computed(() => ({
  hidden: !isMenuOpen.value,
  block: isMenuOpen.value,
  'md:flex': true,
  'md:flex-row': true,
  'md:space-x-4': true,
  'w-full': true,
  'animate-in': isMenuOpen.value,
  'animate-out': !isMenuOpen.value,
}));

</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Righteous);

.custom-font {
  font-family:'Black Ops One', 'Righteous', Arial, sans-serif;
  letter-spacing: 2px;
}

.btn_custom .hamburger {
  animation: rotation_open 0.5s ease-in-out;
}

.btn_custom .close {
  animation: rotation_close 0.5s ease-in-out;
}

@keyframes rotation_open {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotation_close {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0);
  }
}

/* Animations */
.animate-in {
  animation: slide-in 0.7s ease-in-out;
}

.animate-out {
  animation: slide-out 0.7s ease-in-out;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.item {
  color:#007BFF;
  text-decoration:none;
  text-transform:uppercase;
  transition:0.3s ease-in-out;
  -webkit-transition:0.3s ease-in-out;
  text-align:center;
  position:relative;
  z-index:2;
  text-decoration:none;
  font-family:'Black Ops One', 'Righteous', Arial, sans-serif;
  text-transform:uppercase;
  transition:0.3s ease-in-out;
  -webkit-transition:0.3s ease-in-out;
}
.item:hover:before{
  opacity:1;
  transition:0.3s ease-in-out;
  -webkit-transition:0.3s ease-in-out;
  transform:scale(0.7);
  -webkit-transform:scale(0.7);
}
.item:before {
  content:'';
  transform: scale(0.2);
  -webkit-transform: scale(0.2);
  z-index: -1;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  opacity: 0;
  position: absolute;
  top: -31px;
  height: 100px;
  width: 100px;
  background: none;
  border-radius: 100px;
  border-top: 15px solid #87F5B5;
  border-bottom: 15px solid #87F5B5;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.home-custom:before {
  left: -10px;
}
.about-custom:before {
  left: -4px;
}
.contact-custom:before {
  left: 10px;
}
.web-custom:before {
  left: 20px;
}
.other-custom:before {
  left: -5px;
}

.item:hover {
  color: #000;
  transform:scale(1.3);
  -webkit-transform:scale(1.3);
}
</style>
