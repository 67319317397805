<template>
  <div class="container flex flex-col items-center mx-auto my-8 lg:mt-[100px]">
    <h1 class="text-[17px] sm:text-[28px] font-bold text-center mb-2 text-[28px] lg:mb-14 text-blue-600">CÁC DỊCH VỤ CUNG CẤP</h1>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">1. Thiết kế website bán hàng</h1>
        <p class="text-gray-600 mb-4">Thiết kế website bán hàng chuyên nghiệp, chuẩn SEO, dễ dàng quản lý sản phẩm và tối ưu hóa đơn hàng…</p>
        <router-link to="/thiet-ke-website-ban-hang-ca-nhan">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">2. Thiết kế website giới thiệu công ty, Landing Page</h1>
        <p class="text-gray-600 mb-4">Thiết kế website giới thiệu về công ty, doanh nghiệp chuẩn SEO. Xây dựng trang đích thu hút, tối ưu hóa chuyển đổi khách hàng…</p>
        <router-link to="/thiet-ke-website-landing-page">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">3. Thiết kế website thương mại điện tử</h1>
        <p class="text-gray-600 mb-4">Phát triển nền tảng mua sắm trực tuyến mạnh mẽ và an toàn với thiết kế website thương mại điện tử chuyên nghiệp…</p>
        <router-link to="/thiet-ke-website-thuong-mai-dien-tu">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">4. Thiết kế website Blog</h1>
        <p class="text-gray-600 mb-4">Tạo một website cá nhân cho bạn, cho gia đình, nơi lưu lại những khoảnh khắc đẹp và đáng nhớ…</p>
        <router-link to="/thiet-ke-website-blog">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">5. Thiết kế website giá rẻ cho sinh viên làm đồ án</h1>
        <p class="text-gray-600 mb-4">Cung cấp giải pháp và hướng dẫn chi tiết cho đồ án thiết kế website của sinh viên với chi phí thấp…</p>
        <router-link to="/thiet-ke-do-an-website">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-md">
        <h1 class="text-xl font-semibold mb-2">6. Chạy quảng cáo Facebook Ads</h1>
        <p class="text-gray-600 mb-4">Tiếp cận đúng mục tiêu, giúp website của bạn có nhiều lượt traffic hiệu quả hơn…</p>
        <router-link to="/chay-quang-cao-website">
          <button @click="saveScrollPosition" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Chi tiết</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
  const saveScrollPosition = () => {
    sessionStorage.setItem('scrollPosition', window.scrollY); // Lưu vị trí cuộn hiện tại
  }
</script>
