<template>
  <section class="bg-gray-100 py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
      <h2 class="text-3xl font-bold mb-10 text-blue-600">KHÁCH HÀNG CỦA CHÚNG TÔI NÓI GÌ</h2>
      <div class="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        <div v-for="(testimonial, index) in testimonials" :key="index" class="p-10 bg-white rounded-lg shadow-md relative">
          <p class="italic mb-4">"{{ testimonial.text }}"</p>
          <h3 class="text-xl font-semibold">{{ testimonial.author }}</h3>
          <p class="text-sm text-gray-500">{{ testimonial.position }}</p>

          <!-- Rating stars -->
          <div class="flex justify-center items-center absolute bottom-0 left-0 right-0 mb-[10px]">
            <svg v-for="n in 5" :key="n" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" class="w-5 h-5 text-yellow-400">
              <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
            </svg>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const testimonials = [
  { text: 'Website giúp chúng tôi bán nhiều ghế hơn, tăng doanh thu cho cửa hàng.', author: 'Hoàng Minh', position: 'Cửa Hàng Ghế Massage' },
  { text: 'Khách hàng tìm xe dễ dàng và nhanh chóng hơn nhờ thiết kế website chuyên nghiệp.', author: 'Phương Linh', position: 'Chủ Cửa Hàng Xe Đạp' },
  { text: 'Tăng trưởng mạnh nhờ việc áp dụng quảng cáo trực tuyến và tối ưu SEO.', author: 'Anh Tuấn', position: 'Bán Hàng Online' },
  { text: 'Nhiều khách hàng hơn nhờ thiết kế website tối ưu hóa cho SEO.', author: 'Quốc Hùng', position: 'Cửa Hàng Điện Dân Dụng' },
  { text: 'Website thiết kế đẹp và dễ sử dụng, rất hài lòng.', author: 'Lan Anh', position: 'Cửa Hàng Thời Trang' },
  { text: 'Dịch vụ nhanh chóng và chuyên nghiệp, tôi rất tin tưởng.', author: 'Việt Cường', position: 'Cửa Hàng Máy Tính' }
];
</script>
