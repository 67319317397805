<template>
  <div class="relative">
    <!-- Hiển thị trên PC -->
    <img
      src="../assets/images/background/bg-lg.webp"
      alt="Background - Thiết kế website chuẩn SEO"
      class="hidden lg:block"
    />

    <!-- Hiển thị trên Ipad và mobile -->
    <img
      src="../assets/images/background/bg-md.webp"
      alt="Background - Thiết kế website chuẩn SEO"
      class="w-[430px] h-auto lg:hidden md:w-full md:h-[430px] md:object-contain"
    />
  </div>
</template>
