<template>
<section class="relative text-white py-6">
  <div class="absolute inset-0 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 animate-gradient-x"></div>
  <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
    <h2 class="text-3xl font-bold mb-4">Sẵn sàng để bắt đầu?</h2>
    <p class="mb-8">Liên hệ với chúng tôi hôm nay để thảo luận về dự án thiết kế trang web của bạn.</p>
    <button class="animate-bounce bg-white text-blue-600 font-semibold py-3 px-6 rounded-lg shadow-md hover:bg-gray-200 hover:shadow-lg transition-transform transform hover:scale-105">
      <router-link to="/contact">
        Liên hệ ngay
      </router-link>
    </button>
    
  </div>
</section>
</template>
<style>
@keyframes gradient-x {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.animate-gradient-x {
  animation: gradient-x 5s ease infinite;
  background-size: 200% 200%;
}

</style>
